import type { ImageObject, Organization } from 'schema-dts';

export const LOGO_URL =
  'https://cdn.sanity.io/images/ztw74qc4/production/5996345d9ecc6dd67c97268a5ab623bf60700b19-1200x627.png';

const Logo: ImageObject = {
  '@type': 'ImageObject',
  url: LOGO_URL,
};

export const transcendDescription =
  'Transcend is a privacy platform that empowers brands to enhance regulatory stances for the laws of tomorrow and improve customer relationships through respectful and compliant data transparency, consent, and control.';
export const schemaOrganization: Organization = {
  '@type': 'Organization',
  name: 'Transcend',
  description: transcendDescription,
  foundingDate: '2017',
  location: 'San Francisco, California',
  url: 'https://transcend.io',
  keywords: [
    'privacy',
    'data privacy infrastructure',
    'security',
    'consent management',
    'GDPR',
    'CCPA',
  ],
  founder: [
    {
      '@type': 'Person',
      name: 'Ben Brook',
      jobTitle: 'Chief Executive Officer',
    },
    {
      '@type': 'Person',
      name: 'Mike Farrell',
      jobTitle: 'Chief Technology Officer',
    },
  ],
  logo: Logo,
  sameAs: [
    'https://www.linkedin.com/company/transcend-io',
    'https://github.com/transcend-io',
    'https://www.crunchbase.com/organization/transcend',
    'https://www.instagram.com/transcend.io',
    'https://x.com/transcend_io',
    'https://www.youtube.com/@transcend_io',
  ],
};
